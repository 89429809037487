<!-- =========================================================================================
  File Name: InviteUser.vue
  Description: Invite User - Popup component
  ----------------------------------------------------------------------------------------
========================================================================================== -->

<template>
  <div class="pop-up">
    <div class="modal_footer">
      <vs-input disabled class="inputx copy-link-input" placeholder="Disabled" v-model="text" />
      <vs-button
        class="modal_copy_link onbmodal_btn"
        v-clipboard:copy="text"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >Copy Link</vs-button>
    </div>

    <div class="Excel-bot-div">
      <vs-divider>
        <span>or</span>
      </vs-divider>
    </div>
    <social-sharing :url="text" inline-template>
      <div class="onboard-socialicon">
        <network network="whatsapp">
          <img
            src="@/Core/assets/images/pages/carousel/whatsapp.png"
            class="img-responsive social-icon-img"
          />
        </network>
        <network network="facebook">
          <img
            src="@/Core/assets/images/pages/carousel/facebook.png"
            class="img-responsive social-icon-img"
          />
        </network>
        <network network="email">
          <img
            src="@/Core/assets/images/pages/carousel/new-email-button.png"
            class="img-responsive social-icon-img"
          />
        </network>
        <network network="linkedin">
          <img
            src="@/Core/assets/images/pages/carousel/linkedin.png"
            class="img-responsive social-icon-img"
          />
        </network>
        <network network="twitter">
          <img
            src="@/Core/assets/images/pages/carousel/twitter.png"
            class="img-responsive social-icon-img"
          />
        </network>
      </div>
    </social-sharing>
    <!-- <div class="onboard-socialicon">
      <img
        src="@/Core/assets/images/pages/carousel/square-add-button.png"
        class="img-responsive social-icon-img"
      />
      <img
        src="@/Core/assets/images/pages/carousel/whatsapp.png"
        class="img-responsive social-icon-img"
      />
      <img
        src="@/Core/assets/images/pages/carousel/facebook.png"
        class="img-responsive social-icon-img"
      />
      <img
        src="@/Core/assets/images/pages/carousel/twitter.png"
        class="img-responsive social-icon-img"
      />
      <img
        src="@/Core/assets/images/pages/carousel/linkedin.png"
        class="img-responsive social-icon-img"
      />
      <img
        src="@/Core/assets/images/pages/carousel/new-email-button.png"
        class="img-responsive social-icon-img"
      />
    </div>-->
  </div>
</template>

<script>
import "@/Core/Views/Commoncss/componentCss/Mycommunity-sidebar.css";
export default {
  data() {
    return {};
  },
  computed: {
    imageBaseUrl() {
      return this.baseUrlMagitt;
    },
    text() {
      return (
        window.document.location.origin +
        "/pages/mainregister?cid=" +
        localStorage.getItem("communityID")
      );
    }
  },
  methods: {
    onCopy() {
      this.$vs.notify({
        title: "Success",
        text: "Text copied successfully",
        color: "success",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-check-circle"
      });
    },
    onError() {
      this.$vs.notify({
        title: "Failed",
        text: "Error in copying text",
        color: "danger",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-alert-circle"
      });
    }
  },
  mounted() {},
  components: {},

  created() {}
};


</script>

<style>
.add_grp_member {
  height: 55px;
  margin-left: 14px;
}
.modal_footer {
  display: flex;
}
.inputx {
  flex: 1 1 auto;
  width: 100%;
}
.onbmodal_btn {
  height: 65px;
  border-radius: 0px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  font-weight: 500;
}
</style>

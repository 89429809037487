<template>
  <div>
    <modify-group
      :isSidebarActive="openGroupSidebar"
      @closeSidebar="toggleGroupDataSidebar"
      @groupCreated="getNewGroup"
      :data="groupSidebarData"
    />
    <DivLoader :id="loaderID" :isLoading="isLoading">
      <template slot="content">
        <div class="vx-row card-bg groups-card-h md-10">
          <div class="w-full">
            <div class="groups-create-btn">
              <vs-button
                v-if="$acl.check('admin') && userGroups.length > 0"
                size="small"
                icon-pack="feather"
                icon="icon-plus"
                class="carsouel_group_create_btn"
                type="border"
                @click="addNewGroup"
                >Create</vs-button
              >
            </div>
          </div>
          <div class="desktop-groups-width">
            <div v-if="userGroups.length > 0 && !isLoading">
              <div class="groups_card_radius">
                <swiper
                  :options="swiperGroupsOption"
                  ref="mySwiper"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :key="$vs.rtl"
                >
                  <swiper-slide
                    v-for="group of userGroups"
                    :key="group.user_dashboard_cards_UniqueId"
                  >
                    <vx-card
                      class="cust-bShadow cust-bottedges-rad"
                      @click="viewGroup(group)"
                    >
                      <div class="MyimgClass flex">
                        <div slot="no-body" class="w-1/3">
                          <img
                            v-if="group.group_icon != null"
                            :src="magittUrl + group.group_icon"
                            @error="
                              group.group_icon =
                                'uploads/defaultImages/default_post.png'
                            "
                            alt="content-img"
                            class="imgTopedges-sm cust-topedges-rad"
                          />
                          <vs-avatar
                            v-if="group.group_icon == null"
                            :src="null"
                            :text="group.group_name"
                            alt="content-img"
                            class="imgTopedges-sm cust-topedges-rad cust-bord-rem m-0 bg-primary"
                          />
                        </div>

                        <div class="disc-usersec w-3/5">
                          <div class="grp-titdiv">
                            <div class>
                              <h5
                                class="mb-0 h4-class fontWeight-600 text-Eclipse-Dot-grp-cap"
                              >
                                {{ group.group_name }}
                              </h5>
                            </div>
                            <div
                              class="button_brp_dashboard"
                              v-if="group.isPrivateGroup"
                            >
                              <vs-button
                                class="bg-primary dashboard_font_size_text Grp-join-btn"
                                size="small"
                                type="filled"
                                :disabled="group.requestsent != null"
                                @click.stop="
                                  requestToJoin(group.groupId, group.group_name)
                                "
                              >
                                {{
                                  group.requestsent != null
                                    ? "REQUEST SENT"
                                    : "JOIN"
                                }}
                              </vs-button>
                            </div>
                          </div>
                          <div>
                            <p
                              class="grp-caption-colr h4-class text-Eclipse-Dot-grp-cap"
                            >
                              {{ group.group_description }}
                            </p>
                          </div>
                          <div claas="grp-botm-sec">
                            <div
                              class="w-full group-userList"
                              v-if="!group.isPublicGroup"
                            >
                              {{
                                group.membercount == 1
                                  ? group.membercount + " member"
                                  : group.membercount + " members"
                              }}
                              <!-- 15 Members -->
                              <!-- <img
                        src="../../assets/portrait/SBI/f15.jpg"
                        class="imgRadius-xs"
                      />
                      <img
                        src="../../assets/portrait/SBI/f15.jpg"
                        class="imgRadius-xs"
                      />
                      <img
                        src="../../assets/portrait/SBI/f15.jpg"
                        class="imgRadius-xs"
                        />-->
                              <!-- <img
                        src="../../assets/portrait/SBI/f15.jpg"
                        class="image_list_section_dashboard groups_img_overlapp_dashboard4"
                        />-->
                            </div>
                            <div
                              class="w-full group-userList"
                              v-if="group.isPublicGroup"
                            >
                              PublicChannel
                            </div>
                            <div class="groups-arrow-al">
                              <vs-button
                                color="primary"
                                class="disscussion_icon_align"
                                type="flat"
                                icon-pack="feather"
                                icon="icon-arrow-right"
                              ></vs-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </vx-card>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </div>
            </div>
            <div v-if="userGroups.length === 0 && !isLoading">
              <div class="Default-elsepart flex">
                <img
                  src="../../../../assets/images/Defaultcardimg/channel.png"
                  class="cust-def-image"
                />

                <div class="text-left cust-heading-mg">
                  <span class="cust-titlespan text-align"
                    >Start the collaborations using channels</span
                  >
                  <p class="pt-2 cust-subtitle">
                    Just hit the button to get started
                  </p>
                  <div class="mt-4 mb-3">
                    <vs-button
                      color="primary"
                      class="font-medium cust-deffont"
                      type="filled"
                      @click="addNewGroup"
                      >CREATE CHANNEL</vs-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DivLoader>
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const dashboardRepository = RepositoryFactory.get("dashboard");
const groupsRepository = RepositoryFactory.get("groups");
const notificationRepository = RepositoryFactory.get("notifications");
import ModifyGroup from "../../group/ModifyGroup";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import DivLoader from "@/Core/Views/CommonPlugins/div-loader/DivLoader";

export default {
  components: {
    swiper,
    swiperSlide,
    ModifyGroup,
    DivLoader,
  },
  data() {
    return {
      token: {},
      userGroups: [],
      openGroupSidebar: false,
      groupSidebarData: {},
      swiperGroupsOption: {
        slidesPerView: 2.5,
        centeredSlides: false,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1240: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          375: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        },
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      },
      isLoading: true,
      loaderID: "home-channels",
    };
  },
  created() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
  },
  methods: {
    getNewGroup() {
      this.userGroups = [];
      this.getGroupsForDashboard();
    },
    toggleGroupDataSidebar(val = false) {
      this.openGroupSidebar = val;
    },
    addNewGroup() {
      this.groupSidebarData = {};
      this.toggleGroupDataSidebar(true);
    },
    async getGroupsForDashboard() {
      this.isLoading = true;
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: {
          userId: this.token.userID,
        },
      };
      const { data } = await dashboardRepository.getGroupsForDashboard(obj);

      this.isLoading = false;
      this.userGroups = data;
      this.getPublicGroupList();
    },
    async getPublicGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getPublicGroups(obj);

      if (data) {
        data.forEach((element) => {
          element.isPublicGroup = true;
          this.userGroups.push(element);
        });
        this.getPrivateGroupList();
        // self.GroupList=data;
      }
    },
    async getPrivateGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getPrivateGroups(obj);

      if (data) {
        data.forEach((element) => {
          element.isPrivateGroup = true;
          // self.userGroups.push(element);
        });
        // self.GroupList=data;
      }
    },
    viewGroup(group) {
      if (!group.isPrivateGroup && !group.isPublicGroup) {
        this.$store.state.groupId = group.groupId;
        this.$router
          .push({
            name: "page-GroupsPage",
            params: { groupId: this.$store.state.groupId },
          })
          .catch(() => {});
      }
    },
    async requestToJoin(groupid, groupName) {
      this.$vs.loading();
      var obj = {
        invitationData: {
          userId: this.token.userID,
          status: 1,
          ownerName: this.token.username,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: [groupid],
      };
      const { data } = await groupsRepository.requestToJoinGroup(obj);

      if (data.ResponseCode == 100) {
        this.getUsersInGroup(groupid, groupName);
        this.$vs.notify({
          title: "Channel Join Request",
          text: "Your request to join this channel has been sent successfully",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
      }
      if (data.ResponseCode == 101) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Channel Join Request",
          text: "Your channel request is under process",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
      }
    },
    async getUsersInGroup(groupId, groupName) {
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupId: groupId,
        userId: this.token.userID,
      };

      const { data } = await groupsRepository.getUsersInGroup(obj);

      if (data.length > 0) {
        this.groupAdminList = data
          .filter((x) => x.group_admin_id != null)
          .map((x) => x.usersDetails_UniqueId);

        this.insertJoinRequestNotification(
          groupId,
          this.groupAdminList,
          groupName
        );
      }
    },
    async insertJoinRequestNotification(groupId, userId, groupName) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "wants to join " + groupName,
            notificationTitle: "Group",
            notificationEntityType: "Group Join Request",
            notificationEntityId: groupId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await notificationRepository.addNotification(payload);
        if (data) {
          this.$vs.loading.close();
        }
      }
    },
  },
  mounted() {
    this.getGroupsForDashboard();
  },
};
</script>

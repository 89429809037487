<template>
  <div class="membHome-parentDiv w-full">
    <div class="card-text card-titleName">
      <span class="h2-class">Members</span>
    </div>
    <DivLoader :id="loaderID" :isLoading="isLoading">
      <template slot="content">
        <div class="memb-top-slid-full membr-scroll">
          <div class="Members-card-scroll">
            <div class="ml-6 mt-4">
              <vx-card
                class="cust-Bshadow-2 def-memcard cursor-pointer"
                @click="openInvitePage()"
              >
                <div class="MyimgClass">
                  <div slot="no-body">
                    <img
                      src="../../../../assets/images/pages/Add User-rafiki (1).png"
                      alt=""
                      class="imgEdge-top imgEdge-sm def-user-img"
                    />
                  </div>
                </div>

                <div class="MembersName-align ">
                  <h5 class="h4-class textHid-child">INVITE</h5>
                </div>
              </vx-card>
            </div>
            <div
              class="Members-Card-ParentDiv"
              v-for="member of newMembers"
              :key="member.userId"
            >
              <HomeMemberView :member="member" />
            </div>
            <div
              class="cust-Bshadow-2 Members-View-allcard"
              @click="viewMembers"
              v-if="newMembers.length > 0"
            >
              <div class="mem-view-btn">
                <h5 class="h4-class">View all</h5>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DivLoader>
    <!--  Start else part  -->
    <!-- <div v-else>
      <div class="ml-6 mt-4">
          <vx-card
            class="cust-Bshadow-2 def-memcard cursor-pointer">
            <div class="MyimgClass">
              <div slot="no-body">
                <img
                    src="../../../../assets/images/Defaultcardimg/add members.png"
                  alt=""
                  class="imgEdge-top imgEdge-sm def-user-img"
                />
            
              </div>
            </div>
          </div>

          <div class="MembersName-align ">
            <h5 class="h4-class textHid-child">INVITE</h5>
          </div>
        </vx-card>
      </div>
    </div> -->
  </div>
</template>

<script>
import DivLoader from "@/Core/Views/CommonPlugins/div-loader/DivLoader";
import HomeMemberView from "./HomeMemberView";
import ViewMembers from "../ViewMembers";
import jwt from "jsonwebtoken";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const dashboardRepository = RepositoryFactory.get("dashboard");
import { currentCommunityName } from "@/Core/config/communityDetails.js";
export default {
  components: {
    ViewMembers,
    DivLoader,
    HomeMemberView
  },
  data() {
    return {
      newMembers: [],
      token: {},
      isLoading: true,
      loaderID: "home-members"
    };
  },
  computed: {},
  methods: {
    async getUsersForDashboard() {
      this.isLoading = true;
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        userData: {
          userId: this.token.uuid
        }
      };
      const { data } = await dashboardRepository.getUsersForDashboard(obj);

      if (data) {
        this.isLoading = false;
        this.newMembers = data;
      } else {
        this.isLoading = false;
      }
    },
    viewMembers() {
      this.$router.push("/community-member-list").catch(() => {});
    },
    openInvitePage() {
      this.$router.push("/pages/excelinvites").catch(() => {});
    }
  },
  mounted() {
    this.getUsersForDashboard();
  },
  created() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
  }
};
</script>

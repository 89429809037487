<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding mysidebar_1"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="view-memberssidebar-parent">
      <div class="flex px-4 add_post text-light bg-primary view_member_topbar">
        <feather-icon
          icon="ChevronLeftIcon"
          @click.stop="isSidebarActiveLocal = false"
          class="cursor-pointer h2-class"
        ></feather-icon>
        <vs-button class="h2-class h3-upp-class">VIEW MEMBERS</vs-button>
      </div>
      <div class="view-memb-searchbar mt-members-view">
        <vs-input
          icon-pack="feather"
          icon="icon-search"
          icon-after
          vs-placeholder="Nombre"
          class="view-memb-serachbar"
          v-model="memberSearch"
          @keyup="searchAllMembers()"
        />
      </div>
      <!-- <vx-card class="mt-base card_header"> -->
      <div class="mt-base card_header">
        <div class="view-sixebar-memberscard">
          <ul class="page-suggestions-list view-mem-side-ul">
            <li
              class="page-suggestion flex items-center"
              v-for="(user, index) of users"
              :key="index"
            >
              <div class="mr-3">
                <vs-avatar
                  v-if="user.usersDetails_UserImage != null"
                  class="view-memb-image-width"
                  :src="magittUrl + user.usersDetails_UserImage"
                  size="35px"
                />
                <vs-avatar
                  v-if="user.usersDetails_UserImage == null"
                  class="view-memb-image-width"
                  :src="magittUrl + 'uploads/defaultImages/default_user.jpg'"
                  size="35px"
                />
              </div>
              <div
                class="CarouselResponsiveBreakpoints-members-header ecllipses-member-name"
              >
                <h5
                  class="h4-class CarouselResponsiveBreakpoints-members-line_height_dashboard font-semibold h4-class chat_slider_user_bold"
                >
                  {{ user.usersDetails_UserName }}
                </h5>
              </div>
            </li>
            <infinite-loading
              ref="infiniteLoadingMembers"
              @infinite="infiniteHandlerMembers"
            >
              <div slot="no-more">
                <p>All Members Displayed</p>
              </div>
            </infinite-loading>
          </ul>
        </div>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) {
        return;
      } else {
        let token = localStorage.getItem("token");
        this.token = jwt.decode(token);

        this.showAllMembers();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  data() {
    return {
      memberSearch: "",
      users: [],
      memberPage: 1,
      memberOffset: 0,
      memberLimit: 3000,
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },
  },
  methods: {
    async searchAllMembers() {
      if (this.memberSearch.length > 1) {
        let obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          userData: {
            searchTerm: this.memberSearch,
          },
          paging: {
            limit: 12,
            offset: 0,
          },
        };

        const { data } = await userRepository.searchAllUsers(obj);

        if (data.length) {
          this.users = data;
        }
      } else if (this.memberSearch.length == 0) {
        this.showAllMembers();
      }
    },
    async showAllMembers() {
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: {
          userID: this.token.uuid,
        },
        paging: {
          limit: this.memberLimit,
          offset: this.memberOffset,
        },
      };

      const { data } = await userRepository.getAllUsers(obj);

      return new Promise((resolve, reject) => {
        if (data) {
          if (data.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
          data.forEach((element) => {
            this.users.push(element);
          });
        } else {
          reject(false);
        }
      });
      // if (data.length) {
      //   this.users = data;
      // }
    },
    infiniteHandlerMembers($state) {
      this.showAllMembers().then((data) => {
        if (data) {
          this.memberPage += 1;
          this.memberOffset += this.memberLimit;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  },
  mounted() {},
};
</script>

<style>
.view-memb-serachbar {
  width: 100% !important;
}
/* div > div > input.vs-inputx.vs-input--input.normal.hasIcon{
    
    padding: 10px;
} */
.mt-members-view {
  margin: 15px;
  margin-top: 17%;
  border: 1px solid #eee !important;
}
.view-memb-serachbar > div > .vs-input--input {
  background: #ffff !important;
}
.view-memb-header {
  padding: 1.5rem 1.5rem 0;
}
.view-mem-side-ul {
  height: 78vh;
  padding: 1rem;
  overflow-y: auto;
}
.view-memb-image-width {
  width: 50px !important;
  height: 50px !important;
}
.input-field {
  border-radius: 0px !important;
}
.input-field > div > input.vs-inputx.vs-input--input.normal {
  border-radius: 6px;
}

.input_field_bottom > div > input.vs-inputx.vs-input--input.normal {
  border-radius: 6px;
  border: #ededed !important;
  border-bottom: 2px solid #eee !important;
}
.create_btn {
  border-radius: 0px !important;
  position: absolute;
  bottom: 0px;
}
.view_member_topbar {
  position: absolute;
  z-index: 999;
}
.margin-top-member-view {
  margin-top: 3.5rem !important;
}
.sidebar-viewmember {
  margin: 5px;
}
.view-mem-side-ul::-webkit-scrollbar {
  width: 0;
}
.vs-con-input-label {
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100% !important;
}
/* .view-memberssidebar-parent >div {
  margin-top:0px !important;
} */
/* .add-new-data-sidebar > div > div > div{
  margin-bottom:0px !important;
} */
</style>

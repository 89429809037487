<template class="store">
  <div class="">
    <ViewPostSidebar
      @closeSidebar="showPopup(false)"
      :show="blurpostopen"
      :groupPost="viewPostSidebarData"
    ></ViewPostSidebar>
    <vs-popup
      class="holamundo add_group_CarouselResponsiveBreakpoints_headerbg"
      title="Share Link to invite"
      :active.sync="inviteUserPopupActive"
    >
      <InviteUser></InviteUser>
    </vs-popup>
    <div v-show="!blurpostopen">
      <div class="vx-row card-bg md-10 Members-Card-Height">
        <HomeMembersSection></HomeMembersSection>
      </div>
      <div class="flex mob-view-notifi">
        <div
          class="vx-row m-0 lg:w-1/4 mob-viewnone p-5 sm:w-1/4 w-full notif-md-rg bg-white rounded-lg"
        >
          <HomeNotificationSection
            @showPopup="viewSinglePost"
          ></HomeNotificationSection>
        </div>
        <div
          class="vx-row m-0 lg:w-3/4 dics-pd-left disc-card-height disc-margin"
        >
          <vs-tabs class="rounded-lg">
            <vs-tab label="Channels">
              <HomeGroupsSection></HomeGroupsSection>
              <div></div>
            </vs-tab>
            <vs-tab label="Utilities">
              <HomeUtilitiesSection></HomeUtilitiesSection>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
    </div>
    <div class="flex mob-view-trend"></div>
  </div>
</template>

<script>
import "@/Core/Views/Commoncss/componentCss/CarouselResponsiveBreakpoints.css";
import InviteUser from "../NewCommunitySetup/components/InviteUser";
import HomeMembersSection from "./components/HomeMembersSection";
import HomeNotificationSection from "./components/HomeNotificationsSection";
import HomeGroupsSection from "./components/HomeGroupsSection";
import HomeUtilitiesSection from "./components/HomeUtilitiesSection";
import ViewPostSidebar from "@/Core/Views/pages/group/ViewPostSidebar";

export default {
  data() {
    return {
      cards: [],
      inviteUserPopupActive: false,
      blurpostopen: false,
      viewPostSidebarData: {},
    };
  },
  components: {
    InviteUser,
    HomeMembersSection,
    HomeNotificationSection,
    HomeGroupsSection,
    HomeUtilitiesSection,
    ViewPostSidebar,
    // VideoJSWavesurfer
  },
  methods: {
    showPopup(status) {
      this.blurpostopen = status;
    },
    viewSinglePost(postData) {
      this.viewPostSidebarData = postData;
      this.showPopup(true);
    },
    inviteUser() {
      this.inviteUserPopupActive = true;
    },
  },
};
</script>

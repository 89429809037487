<template>
  <div id="extra-component-vue-awesome-swiper-demo">
    <carousel-responsive-breakpoints></carousel-responsive-breakpoints>
  </div>
</template>

<script>
import CarouselResponsiveBreakpoints from "./CarouselResponsiveBreakpoints.vue";

export default {
  components: {
    CarouselResponsiveBreakpoints
  }
};
</script>

<template>
  <vx-card
    class="cust-Bshadow-2  cursor-pointer"
    @click="showProfile(member.uid)"
  >
    <div class="MyimgClass">
      <div slot="no-body">
        <img
          v-if="member.userImage != null"
          :src="magittUrl + member.userImage"
          @error="member.userImage = null"
          alt="content-img"
          class="imgEdge-top imgEdge-sm def-user-img"
        />
        <vs-avatar
          v-else
          :text="username"
          :src="null"
          alt="content-img"
          class="imgEdge-top imgEdge-sm def-user-img bg-primary"
        />
      </div>
    </div>

    <div class="MembersName-align textHid-parent">
      <h5 class="h4-class textHid-child">{{ username }}</h5>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    username() {
      return this.member.userName.trim();
    },
    magittUrl() {
      return this.baseUrlMagitt;
    }
  },
  methods: {
    showProfile(userid) {
      this.$store.state.memberId = userid;
      this.$router.push("/community-user-profile").catch(() => {});
    }
  }
};
</script>
